// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-assets-personal-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/assets/Personal.mdx" /* webpackChunkName: "component---src-assets-personal-mdx" */),
  "component---readme-md": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-assets-professional-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/assets/Professional.mdx" /* webpackChunkName: "component---src-assets-professional-mdx" */),
  "component---src-assets-social-media-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/assets/SocialMedia.mdx" /* webpackChunkName: "component---src-assets-social-media-mdx" */),
  "component---src-pages-index-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-styleguide-color-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/styleguide/Color.mdx" /* webpackChunkName: "component---src-styleguide-color-mdx" */),
  "component---src-styleguide-icons-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/styleguide/Icons.mdx" /* webpackChunkName: "component---src-styleguide-icons-mdx" */),
  "component---src-components-alert-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Alert.mdx" /* webpackChunkName: "component---src-components-alert-mdx" */),
  "component---src-styleguide-images-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/styleguide/Images.mdx" /* webpackChunkName: "component---src-styleguide-images-mdx" */),
  "component---src-styleguide-grid-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/styleguide/Grid.mdx" /* webpackChunkName: "component---src-styleguide-grid-mdx" */),
  "component---src-components-badge-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Badge.mdx" /* webpackChunkName: "component---src-components-badge-mdx" */),
  "component---src-components-avatar-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Avatar.mdx" /* webpackChunkName: "component---src-components-avatar-mdx" */),
  "component---src-components-button-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Button.mdx" /* webpackChunkName: "component---src-components-button-mdx" */),
  "component---src-components-button-dropdown-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/ButtonDropdown.mdx" /* webpackChunkName: "component---src-components-button-dropdown-mdx" */),
  "component---src-components-checkbox-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Checkbox.mdx" /* webpackChunkName: "component---src-components-checkbox-mdx" */),
  "component---src-components-collapse-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Collapse.mdx" /* webpackChunkName: "component---src-components-collapse-mdx" */),
  "component---src-components-description-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Description.mdx" /* webpackChunkName: "component---src-components-description-mdx" */),
  "component---src-components-file-tree-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/FileTree.mdx" /* webpackChunkName: "component---src-components-file-tree-mdx" */),
  "component---src-components-icon-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Icon.mdx" /* webpackChunkName: "component---src-components-icon-mdx" */),
  "component---src-components-fieldset-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Fieldset.mdx" /* webpackChunkName: "component---src-components-fieldset-mdx" */),
  "component---src-components-image-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Image.mdx" /* webpackChunkName: "component---src-components-image-mdx" */),
  "component---src-components-input-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Input.mdx" /* webpackChunkName: "component---src-components-input-mdx" */),
  "component---src-components-link-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Link.mdx" /* webpackChunkName: "component---src-components-link-mdx" */),
  "component---src-components-loading-dots-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/LoadingDots.mdx" /* webpackChunkName: "component---src-components-loading-dots-mdx" */),
  "component---src-components-note-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Note.mdx" /* webpackChunkName: "component---src-components-note-mdx" */),
  "component---src-components-popover-menu-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/PopoverMenu.mdx" /* webpackChunkName: "component---src-components-popover-menu-mdx" */),
  "component---src-components-radio-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Radio.mdx" /* webpackChunkName: "component---src-components-radio-mdx" */),
  "component---src-components-scroller-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Scroller.mdx" /* webpackChunkName: "component---src-components-scroller-mdx" */),
  "component---src-components-select-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Select.mdx" /* webpackChunkName: "component---src-components-select-mdx" */),
  "component---src-components-show-more-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/ShowMore.mdx" /* webpackChunkName: "component---src-components-show-more-mdx" */),
  "component---src-components-skeleton-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Skeleton.mdx" /* webpackChunkName: "component---src-components-skeleton-mdx" */),
  "component---src-components-slider-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Slider.mdx" /* webpackChunkName: "component---src-components-slider-mdx" */),
  "component---src-components-snippet-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Snippet.mdx" /* webpackChunkName: "component---src-components-snippet-mdx" */),
  "component---src-components-spacer-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Spacer.mdx" /* webpackChunkName: "component---src-components-spacer-mdx" */),
  "component---src-components-spinner-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Spinner.mdx" /* webpackChunkName: "component---src-components-spinner-mdx" */),
  "component---src-components-status-dot-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/StatusDot.mdx" /* webpackChunkName: "component---src-components-status-dot-mdx" */),
  "component---src-components-switch-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Switch.mdx" /* webpackChunkName: "component---src-components-switch-mdx" */),
  "component---src-components-tag-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Tag.mdx" /* webpackChunkName: "component---src-components-tag-mdx" */),
  "component---src-components-table-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Table.mdx" /* webpackChunkName: "component---src-components-table-mdx" */),
  "component---src-components-text-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Text.mdx" /* webpackChunkName: "component---src-components-text-mdx" */),
  "component---src-components-textarea-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Textarea.mdx" /* webpackChunkName: "component---src-components-textarea-mdx" */),
  "component---src-components-toast-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Toast.mdx" /* webpackChunkName: "component---src-components-toast-mdx" */),
  "component---src-components-toggle-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Toggle.mdx" /* webpackChunkName: "component---src-components-toggle-mdx" */),
  "component---src-components-tooltip-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Tooltip.mdx" /* webpackChunkName: "component---src-components-tooltip-mdx" */),
  "component---src-components-video-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/Video.mdx" /* webpackChunkName: "component---src-components-video-mdx" */),
  "component---src-components-video-card-mdx": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/src/components/VideoCard.mdx" /* webpackChunkName: "component---src-components-video-card-mdx" */),
  "component---src-pages-404-js": () => import("/Users/bruce.bentley/Projects/Personal/docz.brucebentley.dev/.docz/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

